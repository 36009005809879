@import '../../../general/scss/settings/variables.scss';

.event-details {
    .event-header {
        font-size: 2rem;
        font-weight: $font-weight-semibold;
    }

    h1 {
        font-size: 1.45rem;
        font-weight: $font-weight-semibold;
        padding-bottom: 0 !important;
    }

    h3 {
        margin-top: 1.5rem !important;
    }

    .speaker-profile {
        padding-bottom: 20px;

        .photo-placeholder {
            height: 128px;
            width: 128px;
            display: block;
            position: absolute;
            z-index: 200;
            background: url(images/photocover-white.png) no-repeat transparent;
        }

        .photo {
            z-index: 100;
            position: absolute;
            width: 128px;
            height: 128px;
        }

        .speaker-photo {
            margin: 0 auto;
            position: relative;
            height: 128px;
        }

        .name-role {
            font-size: 2em;
            font-weight: 300;
            padding: 0 0 15px;
            border-bottom: 1px solid #e3e3e3;
            margin: 30px 0 10px 0;
            display: inline-block;
            line-height: 37px;
            width: 100%;

            .intro {
                display: block;
            }
        }

        .image {
            width: 20%;
        }

        .content {
            width: 80%;
        }
    }

    .event-agenda {
        p {
            position: relative;

            .agtime,
            .agdesc {
                display: block;
                margin-bottom: .25rem;
            }

            @include media-breakpoint-up(lg) {
                .agtime {
                    display: table-cell;
                    width: 180px;
                }

                .agdesc {
                    display: table-cell;
                }

                &.agborders,
                &.agboarders {
                    border-bottom: 1px solid #999;
                    border-top: 1px solid #999;
                    padding-top: .5rem;
                }
            }
        }
    }
}

#map-canvas-event {
    width: 100%;
    height: 350px;
    margin-bottom: 1rem;
}


.home-cta {
    @include gradientBorder(top);

    @include media-breakpoint-up(lg) {
        p {
            width: 80%;
        }
    }
}

@include media-breakpoint-down(sm) {
    .home-cta {
        .container {
            padding-left: 0 !important;
            padding-right: 0 !important;

            .col {
                padding-bottom: 1.75rem !important;

                &:not(:last-child) {
                    border-bottom: rem-calc(3) solid $white;
                }
            }
        }
    }
}

.home-panel {
    padding: 1rem;
}

@include media-breakpoint-down(sm) {
    .home-panel {
        padding: .5rem 1rem;
    }

    .home-banner {
        padding-left: 0;
        padding-right: 0;
    }
}

@include media-breakpoint-up(lg) {
    $image-path: 'src/images';
    $name: 'home-intro';
    $fallback-extension: 'png';
    $retina-suffix: '_2x';

    .home-intro {
        padding: 1rem 2rem 0;

        .container {
            background-image: url(#{$image-path}/#{$name}-left.#{$fallback-extension}),
            url(#{$image-path}/#{$name}-right.#{$fallback-extension});
            background-position: bottom left,
                /* this positions the first image */
                bottom right;
            /* this positions the second image */
            background-repeat: no-repeat;
            /* this applies to both images */
            background-size: 35%;
            padding-bottom: 2rem;
            margin-bottom: -2px;
        }

        @media only screen and (-moz-min-device-pixel-ratio: 1.5),
        only screen and (-o-min-device-pixel-ratio: 3/2),
        only screen and (-webkit-min-device-pixel-ratio: 1.5),
        only screen and (min-device-pixel-ratio: 1.5) {
            .container {
                background-image: url(#{$image-path}/#{$name}-left#{$retina-suffix}.#{$fallback-extension}),
                url(#{$image-path}/#{$name}-right#{$retina-suffix}.#{$fallback-extension});
            }
        }
    }
}